import React, { useRef, useEffect } from "react";
import CustomButton from "./CustomButton";

import lottie from "lottie-web";
import successAnim from "../assets/success.json";
import { Route, useLocation } from "react-router-dom";

const SuccessPage = () => {
  const animRef = useRef(null);
  let returnURL = "";
  const { search } = useLocation();

  try {
    returnURL = search
    .split("&")
    .filter((item) => item.indexOf("returnURL") > -1)[0]
    .split("=")[1];
  } catch (err) {}
  console.log("returnURLXX2XX");
  returnURL = decodeURIComponent(returnURL);
  console.log(returnURL);	
  
  useEffect(() => {
    lottie.loadAnimation({
      container: animRef.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: successAnim,
    });
  }, []);



  return (
    <>
      <div className="page">
        <div ref={animRef} className="lottie-anim" />
        <p>Success</p>
      </div>
      <div className="footer">
        <CustomButton onClick={() => window.location = returnURL} text="Go back" />
      </div>
    </>
  );
};

export default SuccessPage;
