import { useState, useEffect } from "react";

import svgX from "./assets/x.svg";

import SearchPage from "./components/SearchPage";
import ProceessingPage from "./components/ProceessingPage";
import SuccessPage from "./components/SuccessPage";
import FailPage from "./components/FailPage";

import { Route, useLocation } from "react-router-dom";

import * as axiosFns from "./axios";

const App = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [populateValue, setPopulateValue] = useState("");
  const [db, setDb] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(
    "Unknown error. Please contact your PMS provider."
  );
  const { search } = useLocation();
  let accountId = "";
  let shopId = "";
  let saleId = "";
  let workorderID = "";
  let registerID;
  let roomId = "";
  let refundId = "0";
  let operationName = "";
  let accountDataId = "";
  let guestName = "";
  let reservationId = "";
  let isPopulate = "";
  let customerId = "";
  let returnURL = "";


  try {
    returnURL = search
    .split("&")
    .filter((item) => item.indexOf("returnURL") > -1)[0]
    .split("=")[1];
  } catch (err) {}
  console.log("returnURL");
  returnURL = decodeURIComponent(returnURL);
  console.log(returnURL);

   //console.log("----------------------------------------------------------------------")
  //console.log(window.location.href)
  let SplitAmount = ""
  try {
    SplitAmount = search
      .split("&")
      .filter((item) => item.indexOf("SplitAmount=1") > -1)[0]
      .split("=")[1];
  } catch (err) {}
  //console.log(SplitAmount);
  //console.log("----------------------------------------------------------------------")


  if (!!search) {
    try {
      customerId = search
        .split("&")
        .filter((item) => item.indexOf("customerID") > -1)[0]
        .split("=")[1];
    } catch (err) {}

    try {
      accountId = search
        .split("&")
        .filter((item) => item.indexOf("accountID") > -1)[0]
        .split("=")[1];
      } catch (err) {}

      try{
      saleId = search
        .split("&")
        .filter((item) => item.indexOf("saleID") > -1)[0]
        .split("=")[1];
      } catch (err) {}

      try{
      workorderID = search
        .split("&")
        .filter((item) => item.indexOf("workorderID") > -1)[0]
        .split("=")[1];
      } catch (err) {}
      console.log(workorderID);

      try{
      workorderID = search
          .split("&")
          .filter((item) => item.indexOf("saleID") > -1)[0]
          .split("=")[1];
        } catch (err) {}
      console.log(workorderID);

      try{
      shopId = search
        .split("&")
        .filter((item) => item.indexOf("shopID") > -1)[0]
        .split("=")[1];
      } catch (err) {}

      try{
      registerID = search
        .split("&")
        .filter((item) => item.indexOf("registerID") > -1)[0]
        .split("=")[1];
    } catch (err) {}

    try {
      refundId = search
        .split("&")
        .filter((item) => item.indexOf("refund") > -1)[0]
        .split("=")[1];
    } catch (err) {}
    try {
      operationName = search
        .split("&")
        .filter((item) => item.indexOf("operation") > -1)[0]
        .split("=")[1];
    } catch (err) {}
  }

  function doEvent( obj, event ) {
    var event = new Event( event, {target: obj, bubbles: true} );
    return obj ? obj.dispatchEvent(event) : false;
  }

  
      const fetchData = () => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("X-Comapny-id", accountId);
      myHeaders.append("X-Customer-id", accountId);
      myHeaders.append("X-Operation", "SplitAmount");
      myHeaders.append("X-Option1", saleId);
      let requestOptions = {
        method: "POST",
        headers: myHeaders,
      };
     
      if (SplitAmount == "1") {
        console.log(requestOptions);
        fetch("https://retailrapi.beesql.com/api/custom", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            //console.log(data);
            //console.log(document.getElementById("dnstuff").value)
            console.log("*******************************************************************")
            console.log(data.SaleAmount);
            var el = document.getElementById("dnstuff");
            el.value = data.SaleAmount;
            doEvent(el, 'input' );
            console.log("*******************************************************************")
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    useEffect(() => {
      fetchData();
    }, []);
 


  useEffect(() => {
    if (operationName.indexOf("populate") > -1)
      axiosFns.getPopulate(
        setPopulateValue,
        accountId,
        customerId,
        operationName
      );
  }, []);

  useEffect(() => {
    if (!populateValue) return;
    axiosFns.getSearchData(
      accountId,
      shopId,
      registerID,
      operationName,
      workorderID,
      populateValue,
      setDb,
      setLoading
    );
    setSearchValue(populateValue);
  }, [populateValue]);

  const handleGetSearchData = () =>
    axiosFns.getSearchData(
      accountId,
      shopId,
      registerID,
      operationName,
      workorderID,
      searchValue,
      setDb,
      setLoading
    );

  const handleGetRoomCharges = (
    roomIdInput,
    accountDataIdX,
    guestNameX,
    reservationIdX
  ) => {
    roomId = roomIdInput;
    accountDataId = accountDataIdX;
    guestName = guestNameX;
    reservationId = reservationIdX;
    axiosFns.getRoomCharges(
      accountId,
      saleId,
      roomId,
      accountDataIdX,
      guestNameX,
      reservationIdX,
      refundId,
      operationName,
      setCurrentPage,
      setErrorMsg
    );
  };
  const handleGetRoomChargesRetry = () => {
    axiosFns.getRoomCharges(
      accountId,
      saleId,
      roomId,
      accountDataId,
      guestName,
      reservationId,
      refundId,
      operationName,
      setCurrentPage,
      setErrorMsg
    );
  };

  
  const handleFocusAmount = (event) => event.target.select();

  return (
    <Route path="/">
      <div className="app">
        <div>
          <p onClick={() => window.location = returnURL} className="go-back-text">
            Go back
          </p>
        </div>
        <div className="main-app">
          <div className="header">
            <p>External payment (v4.0)</p>

              { SplitAmount == "1" && (
                  <div id="wrapper99">
                  <header>
                     <div className="containeramount">
                        <h1>
                          Amount: 
                        </h1>
                        <input
                          //type="currency"
                          onFocus={handleFocusAmount}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          className="AmountLabel"
                          id="dnstuff"
                        />
                    </div>
                  </header>
                </div>
              )
              }
              
            
            <div onClick={() => window.location = returnURL} className="x-wrapper">
              <img src={svgX} alt="x" />
            </div>
          </div>

          <div className="main-content">
            {currentPage === 0 && (
              <SearchPage
                db={db}
                loading={loading}
                handleGetSearchData={handleGetSearchData}
                handleGetRoomCharges={handleGetRoomCharges}
                setCurrentPage={setCurrentPage}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            )}
            {currentPage === 1 && <ProceessingPage />}
            {currentPage === 2 && <SuccessPage />}
            {currentPage === 3 && (
              <FailPage
                handleGetRoomChargesRetry={handleGetRoomChargesRetry}
                setCurrentPage={setCurrentPage}
                errorMsg={errorMsg}
              />
            )}
          </div>
        </div>
      </div>
    </Route>
  );
};

export default App;
